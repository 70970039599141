import './App.scss';
import ImageGallery from 'react-image-gallery';
import React from 'react';
import "./styles/image-gallery.scss";
import epc from '../src/images/epc.jpg';
import small_green from '../src/images/small_green.jpg';
import layout from '../src/images/layout.jpg';
import bsc_logo from '../src/images/bsc_logo_flat.svg';
class  App extends React.PureComponent {

	render(){
		const images = [
			{
				originalAlt: `Exterior front`,
				originalTitle: `Exterior front`,
				thumbnailAlt: `Exterior front`,
				thumbnailTitle: `Exterior front`,
				description: `Exterior front`,
				fullscreen: require(`../src/images/full/facade.jpg`),
				original: require(`../src/images/facade.jpg`),
				thumbnail: require(`../src/images/facade.jpg`)
			},
			{
				originalAlt: `Living room`,
				originalTitle: `Living room`,
				thumbnailAlt: `Living room`,
				thumbnailTitle: `Living room`,
				description: `Living room`,
				fullscreen: require(`../src/images/full/living1.jpg`),
				original: require(`../src/images/living1.jpg`),
				thumbnail: require(`../src/images/living1.jpg`)
			},
			{
				originalAlt: `Living room (dining)`,
				originalTitle: `Living room (dining)`,
				thumbnailAlt: `Living room (dining)`,
				thumbnailTitle: `Living room (dining)`,
				description: `Living room (dining)`,
				fullscreen: require(`../src/images/full/living2.jpg`),
				original: require(`../src/images/living2.jpg`),
				thumbnail: require(`../src/images/living2.jpg`)
			},
			{
				originalAlt: `Bedroom 1`,
				originalTitle: `Bedroom 1`,
				thumbnailAlt: `Bedroom 1`,
				thumbnailTitle: `Bedroom 1`,
				description: `Bedroom 1`,
				fullscreen: require(`../src/images/full/bedroom1.jpg`),
				original: require(`../src/images/bedroom1.jpg`),
				thumbnail: require(`../src/images/bedroom1.jpg`)
			},
			{
				originalAlt: `Bedroom 2`,
				originalTitle: `Bedroom 2`,
				thumbnailAlt: `Bedroom 2`,
				thumbnailTitle: `Bedroom 2`,
				description: `Bedroom 2`,
				fullscreen: require(`../src/images/full/bedroom2.jpg`),
				original: require(`../src/images/bedroom2.jpg`),
				thumbnail: require(`../src/images/bedroom2.jpg`)
			},
			{
				originalAlt: `Bedroom 3`,
				originalTitle: `Bedroom 3`,
				thumbnailAlt: `Bedroom 3`,
				thumbnailTitle: `Bedroom 3`,
				description: `Bedroom 3`,
				fullscreen: require(`../src/images/full/bedroom3.jpg`),
				original: require(`../src/images/bedroom3.jpg`),
				thumbnail: require(`../src/images/bedroom3.jpg`)
			},
			{
				originalAlt: `Conservatory`,
				originalTitle: `Conservatory`,
				thumbnailAlt: `Conservatory`,
				thumbnailTitle: `Conservatory`,
				description: `Conservatory`,
				fullscreen: require(`../src/images/full/conservatory.jpg`),
				original: require(`../src/images/conservatory.jpg`),
				thumbnail: require(`../src/images/conservatory.jpg`)
			},
			{
				originalAlt: `Rear garden`,
				originalTitle: `Rear garden`,
				thumbnailAlt: `Rear garden`,
				thumbnailTitle: `Rear garden`,
				description: `Rear garden`,
				fullscreen: require(`../src/images/full/garden.jpg`),
				original: require(`../src/images/garden.jpg`),
				thumbnail: require(`../src/images/garden.jpg`)
			},
			{
				originalAlt: `Kitchen`,
				originalTitle: `Kitchen`,
				thumbnailAlt: `Kitchen`,
				thumbnailTitle: `Kitchen`,
				description: `Kitchen`,
				fullscreen: require(`../src/images/full/kitchen.jpg`),
				original: require(`../src/images/kitchen.jpg`),
				thumbnail: require(`../src/images/kitchen.jpg`)
			},
			{
				originalAlt: `Bathroom`,
				originalTitle: `Bathroom`,
				thumbnailAlt: `Bathroom`,
				thumbnailTitle: `Bathroom`,
				description: `Bathroom`,
				fullscreen: require(`../src/images/full/bathroom.jpg`),
				original: require(`../src/images/bathroom.jpg`),
				thumbnail: require(`../src/images/bathroom.jpg`)
			},
			{
				originalAlt: `Architect drawing`,
				originalTitle: `Architect drawing`,
				thumbnailAlt: `Architect drawing`,
				thumbnailTitle: `Architect drawing`,
				description: `Architect drawing`,
				fullscreen: require(`../src/images/full/drawing.jpg`),
				original: require(`../src/images/drawing.jpg`),
				thumbnail: require(`../src/images/drawing.jpg`)
			},
		];
		const today = new Date();
		const strYear = today.getFullYear();

		return (
			<div className="App">
				<div id={`content`}>
				<header className="App-header">
					<img className={`small-green`} src={small_green} alt={`small drawing`}/>
					<h1>3 bedroom detached bungalow in a superb location:<br/>Acacia Grove, Haxby, York</h1>
					<h1>£385,000</h1>
					</header>
					
					<p className={`intro`}>The original architect's design was called "Kerrysdale", completed in 1981,
						this detached bungalow, benefits from many features, not least,&nbsp;
						<a href={`#location`} alt={`location`}><b>location</b></a> (See below). The property is detached with a secluded and secure,
						established rear garden.
						Spacious rooms and potential, if you wish, for <a href={`#change`} alt={`change`}>changing it
						to be exactly what you want</a>: <i>the home you deserve</i>.</p>
					<h2>Gallery</h2>
					
					<div className={`iframe-container`}>
						<ImageGallery
							// autoPlay={true}
							items={images}
							showBullets={true}
							slideDuration={1000}
							slideInterval={6000}
							thumbnailPosition='right'
						/>
					</div>

					<article>
					<h2>Comprising:</h2>
					<ul>
						<li>Front garden laid to gravel, muscari (grape hyacinths) in spring</li>
						<li>Front porch with overhanging shelter to front door and garage door</li>
						<li>Integral garage with up and over door and storage cupboards/shelves</li>
						<li>Hallway with two large cupboards and loft access</li>
						<li>Bedroom 1 with view over rear garden</li>
						<li>Bedroom 2 with view over front garden and built-in wardrobes</li>
						<li>Bedroom 3 with view over front garden, bay window</li>
						<li>Bathroom with walk in shower cubicle, contemporary heated towel rail, wc and sink unit</li>
						<li>Living/dining room with sliding door to conservatory</li>
						<li>Small conservatory with sliding doors to garden and side windows, new flooring since photos taken</li>
						<li>Freeview aerial in part boarded loft. Loft ladder. Satellite dish on outer bathroom wall</li>
						<li>Kitchen with washing machine, dishwasher, fridge freezer and vented tumble drier</li>
						<li>Well stocked back garden with seating/drying area and small pond (popular with frogs in spring!)</li>
						<li>Large concrete shed with workbench and storage</li>
						<li>Gas central heating</li>
						<li>Lovely neighbours!</li>
					</ul>
					<p>Available with vacant possession. No chain.</p>
					</article>

					<h2>Virtual tour</h2>
					<div className={`printonly`}>
						<p>https://www.bagnall.co.uk/8acaciavr</p>
					</div>
					<div className={`iframe-container noprint`}>
						{/* <iframe src="https://my.matterport.com/show/?m=8ZhAL6WVFGa&amp;play=1" title="3D Tour"></iframe> */}
						<iframe src={`https://www.bagnall.co.uk/8acaciavr`} title={`Virtual Tour`} />
						<p><a
							alt={`Open virtual tour in new tab`}
							href={`https://www.bagnall.co.uk/8acaciavr`}
							target={`_blank`}
							title={`Open virtual tour in new tab`}
						>Open virtual tour in a new tab</a></p>
					</div>
				
					<h2>Location<a alt={`location`} className={`bookmark`} href={`${window.location.href}#location`} name={`location`}>location</a></h2>
					<div className={`printonly`}>
						<p>https://www.google.com/maps/d/embed?mid=1rQDb3DGlDETodQOlmZ_0XinK_PFziiU&ehbc=2E312F</p>
					</div>
					<div className={`iframe-container noprint`}>
						<iframe src="https://www.google.com/maps/d/embed?mid=1rQDb3DGlDETodQOlmZ_0XinK_PFziiU&ehbc=2E312F" title={`Map`} />
						<p><a
							alt={`Open map in new tab`}
							href={`https://www.google.com/maps/d/u/0/edit?mid=1rQDb3DGlDETodQOlmZ_0XinK_PFziiU&usp=sharing`}
							target={`_blank`}
							title={`Open map in new tab`}>Open map in new tab</a>
						</p>
					</div>

					<article>
						
						<p>"Location, location, location", they say. You won't find a better location than this.</p>

						<p>Located on a secluded street a short walk to the village centre,
						with good neighbours, great for:</p>
					
					<ul>
						<li>Supermarkets:
							<ul>
								<li>
									<a
										alt={`Sainsburys Haxby`}
										href={`https://stores.sainsburys.co.uk/2256/haxby`}
										target={`_blank`}
										title={`Sainsburys Haxby`}
									>
									Sainsburys</a>
								</li>
								<li>
									<a
										alt={`Co-op Haxby`}
										href={`https://www.coop.co.uk/store-finder/YO32-3SA/ryedale-court`}
										target={`_blank`}
										title={`Co-op Haxby`}
									>
									The Co-Op</a>
								</li>
							</ul>
						</li>
							<li>
								<a
									alt={`Haxby & Wigginton Surgery`}
									href={`https://www.haxbygroup.co.uk/our-surgeries/york/#haxby-&-wigginton-surgery`}
									target={`_blank`}
									title={`Haxby & Wigginton Surgery`}
								>
								Health centre</a>
							</li>
						<li>Bus stop</li>
						<li>Pharmacies:
							<ul>
								<li>
										<a
											alt={`Boots`}
											href={`https://www.boots.com/stores/5142-york-haxby-village-yo32-2hx`}
											target={`_blank`}
											title={`Boots`}
											>Boots</a>
									</li>
									<li>
										<a
											alt={`Citywide Health`}
											href={`https://www.citywidehealth.co.uk/pharmacies/haxby-pharmacy/`}
											target={`_blank`}
											title={`Citywide Health`}
											>Citywide Health</a>
									</li>
								</ul>
						</li>
						<li>Restaurants:
							<ul>
								<li>
									<a
										alt={`Paddy fields`}
										href={`http://paddyfields.co.uk/`}
										target={`_blank`}
										title={`Paddy fields`}
										>Paddy fields</a>
								</li>
								<li>
									<a
										alt={`Millers Fish &amp; Chips`}
										href={`http://www.millershaxby.com/`}
										target={`_blank`}
										title={`Millers Fish &amp; Chips`}
										>Millers Fish &amp; Chips
									</a>
								</li>
								<li>
									<a
										alt={`Spice Village`}
										href={`http://www.spicevillagehaxby.co.uk/`}
										target={`_blank`}
										title={`Spice Village`}
									>Spice Village</a>
								</li>
								<li>
									<a
										alt={`Haci's Place`}
										href={`https://hacisplaceyork.co.uk/`}
										target={`_blank`}
										title={`Haci's Place`}
									>Haci's Place</a>
								</li>
								<li>
									<a
										alt={`Parvin Indian Restaurant`}
										href={`https://parvinindianrestaurants.co.uk/`}
										target={`_blank`}
										title={`Parvin Indian Restaurant`}
									>Parvin Indian Restaurant</a>
								</li>
								<li>
									Haxby Fortune Inn
								</li>
								<li>
									Janna Spice
								</li>
							</ul>
						</li>
							<li>Coffee shops:
							<ul>
								<li>
										<a
											alt={`The Curious Coffee Company`}
											href={`https://www.curiouscoffee.co/`}
											target={`_blank`}
											title={`The Curious Coffee Company`}
											>The Curious Coffee Company</a>
									</li>
									<li>
										<a
											alt={`Aroma Coffee Shop`}
											href={`https://www.aromaofhaxby.uk/`}
											target={`_blank`}
											title={`Aroma Coffee Shop`}
											>Aroma Coffee Shop</a>
									</li>
									<li>
										<a
											alt={`Costa Coffee`}
											href={`https://www.aromaofhaxby.uk/`}
											target={`_blank`}
											title={`Costa Coffee`}
											>Costa Coffee</a>
									</li>
								</ul>
						</li>
						<li>Pubs:
							<ul>
								<li>The Tiger Inn</li>
								<li>
									<a
										alt={`The Red Lion`}
										href={`https://website--5194164628484098302143-pub.business.site/`}
										target={`_blank`}
										title={`The Red Lion`}
									>The Red Lion</a>
									</li>
									<li>
									<a
										alt={`The Cottage`}
										href={`http://www.cottageinnhaxby.co.uk/`}
										target={`_blank`}
										title={`The Cottage`}
									>The Cottage</a>
									</li>
								<li><a
										alt={`The Black Horse`}
										href={`https://www.blackhorseinnwigginton.co.uk/`}
										target={`_blank`}
										title={`The Black Horse`}
									>The Black Horse</a></li>
							</ul>
							</li>
							<li>
								<a
										alt={`Tower Veterinary Group`}
										href={`https://towervets.co.uk/our-practices/haxby-surgery/`}
										target={`_blank`}
										title={`Tower Veterinary Group`}
								>Veterinary surgery</a>
							</li>
							<li>
								<a
										alt={`Haxby Opticians`}
										href={`http://www.haxbyopticians.co.uk/`}
										target={`_blank`}
										title={`Haxby Opticians`}
								>Haxby Opticians</a>
							</li>
							<li>
								<a
										alt={`The Tipsy Fox Drinks (off licence)`}
										href={`https://www.facebook.com/Tipsy-Fox-Drinks-Co-1957042411196444/`}
										target={`_blank`}
										title={`The Tipsy Fox Drinks (off licence)`}
								>The Tipsy Fox Drinks (off licence)</a>
							</li>
							<li>
								<a
										alt={`Haxby Bakehouse`}
										href={`http://haxbybakehouse.co.uk/`}
										target={`_blank`}
										title={`Haxby Bakehouse`}
								>Haxby Bakehouse</a>
							</li>
						</ul>


						<p>Enjoy the community!</p>
						<ul>
							<li>
							<a
											alt={`Haxby, history, memories and neighbours`}
									href={`https://www.facebook.com/groups/224309378360906`}
									target={`_blank`}
									title={`Haxby, history, memories and neighbours`}
>Haxby, history, memories and neighbours</a>
							</li>
						</ul>
					</article>

					<article>
						<h2>Layout (not to scale)</h2>
						<a className={`layout`} href={layout} target={`_blank`}><img src={layout} alt='Layout' title='Layout' /></a>
						<table className={`striped`} id={`measurements`}>
							<thead>
								<tr>
									<th>Space</th>
									<th>Metric</th>
									<th>Imperial</th>
								</tr>
							</thead>
							<tbody>
							<tr>
									<td>Lounge/dining</td>
									<td>5.77m x 3.51m</td>
									<td>18' 11" x 11' 6"</td>
								</tr>
								<tr>
									<td>Bedroom 1</td>
									<td>4.5m x 2.69m</td>
									<td>14' 9" x 8' 10"</td>
								</tr>
								<tr>
									<td>Bedroom 2</td>
									<td>3.58m x 2.69m</td>
									<td>11' 9" x 8' 10"</td>
								</tr>
								<tr>
									<td>Bedroom 3</td>
									<td>2.9m x 2.29m</td>
									<td>9' 6" x 7' 6"</td>
								</tr>
								<tr>
									<td>Kitchen</td>
									<td>3.89m x 2.59m</td>
									<td>12' 9" x 8' 6"</td>
								</tr>
								<tr>
									<td>Garage</td>
									<td>5.11m x 2.69m</td>
									<td>16' 9" x 8' 10"</td>
								</tr>
							</tbody>
						</table>
					</article>

					<article>
						<h2>A blank canvas!<a alt={`change`} className={`bookmark`} href={`${window.location.href}#change`} name={`change`} >change</a></h2>
						<p>As most of the internal walls are not load bearing, you could easily change the layout,
							for example add an ensuite shower room, convert to an open plan kitchen diner,
							add a larger conservatory.</p>
					</article>

					<article>
						<h2>Energy performance certificate</h2>
						<img className={`epc`} src={epc} alt={`Energy performance certificate`} title={`Energy performance certificate`} />
					</article>

					<footer>
						<h2>Contact:</h2>
						<p>Email: <a href={`mailto:acaciahome@bagnall.co.uk?subject=Acacia%20Enquiry`} target={`_blank`}>acaciahome@bagnall.co.uk</a></p>
						<div id="bottomMenu">
							{/* <Link to="/contact">Contact</Link> */}
						</div>
						<span className={`noprint`}>&copy; {strYear}&nbsp;&nbsp;&nbsp;<img src={bsc_logo} className="logo" alt="BSC logo"/>&nbsp;Bagnall Software Consultants Ltd</span>
					</footer>

				</div>
			</div>
		)
	}
}

export default App;